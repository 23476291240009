import clsx from 'clsx';
import { isFunction } from 'lodash-es';
import React from 'react';
import {
  Disclosure,
  DisclosureGroup,
  DisclosurePanel,
  DisclosureGroupProps,
  DisclosureProps,
  DisclosurePanelProps,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import Icon from '../icon/icon';
import { Pressable } from '../pressable/pressable';

function Accordion({ children, ...props }: DisclosureGroupProps) {
  return <DisclosureGroup {...props}>{children}</DisclosureGroup>;
}

interface AccordionItemProps extends DisclosureProps {
  headerChildren?: React.ReactNode;
}
export function AccordionItem({ headerChildren, children, ...props }: AccordionItemProps) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Disclosure onExpandedChange={setExpanded} {...props}>
      {(bag) => (
        <>
          {headerChildren && (
            <AccordionItemHeader isExpanded={expanded}>
              {isFunction(headerChildren)
                ? headerChildren({ isExpanded: expanded })
                : headerChildren}
            </AccordionItemHeader>
          )}

          {isFunction(children) ? children(bag) : children}
        </>
      )}
    </Disclosure>
  );
}

export function AccordionItemPanel({ children }: DisclosurePanelProps) {
  return <DisclosurePanel>{children}</DisclosurePanel>;
}

export function AccordionItemHeader({
  children,
  isExpanded: isExpanded,
  className,
}: {
  children: React.ReactNode;
  isExpanded: boolean;
  className?: string;
}) {
  return (
    <div>
      <Pressable
        className={twMerge(
          'flex min-h-0 w-full items-center justify-between gap-x-4 py-1 outline-none',
          className,
        )}
        slot="trigger">
        {children}
        <Icon
          className={clsx('flex-end h-4 w-4 transition', isExpanded && 'rotate-180')}
          name={'small-chevron-down'}
          viewBox="0 0 16 16"
        />
      </Pressable>
    </div>
  );
}

Accordion.Item = AccordionItem;
Accordion.Header = AccordionItemHeader;
Accordion.Panel = AccordionItemPanel;
export { Accordion };
